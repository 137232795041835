import {BREAKPOINT} from '../../lib/styles';
import VobApp from 'aac-components/components/VobApp';
import CallrailButton from '../CallrailButton';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import HeroImage from '../HeroImage';
import AcfTooltip from '../Tooltips/AcfTooltip';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';

const HeroSection = (props) => {
    const {
        data: {
            title: {rendered: title = 'Freedom from addiction begins here.'} = {},
            content: {rendered: content = ''} = {},
        },
    } = props;

    return (
        <section className="hero">
            <HeroImage
                src="/static/homepage-assets/homepage-hero-img-mobile.jpeg"
                desktopSrc="/static/homepage-assets/homepage-hero-img.jpeg"
            />

            <div className="hero__content">
                <div className="hero__content--left">
                    <h1 dangerouslySetInnerHTML={{__html: title}} />
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    <div className="hero__content--button">
                        <CallrailButton style={{minWidth: '100%'}} color="#fff" />
                    </div>
                </div>
                <div className="hero__content--right">
                    <VobAbandonModal>
                        <VobApp type="multi-step" />
                    </VobAbandonModal>
                    <AcfTooltip type="ssvob" color="#fff" />
                    <div
                        style={{
                            margin: '0 auto',
                            maxWidth: 'fit-content',
                        }}>
                    </div>
                </div>
            </div>
            <style jsx>{`
                    .hero {
                        position: relative;
                        background: var(--primary-400);
                    }
                    .hero__content--left {
                        color: #fff;
                    }
                    .hero__content {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        position: relative;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                        padding: 64px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content {
                            grid-template-columns: auto 400px;
                            grid-gap: 80px;
                            align-items: center;
                            padding: 100px 0;
                        }
                    }
                    .hero__content--button {
                        max-width: 100%:
                    }
                    @media screen and (min-width: ${BREAKPOINT}){
                        .hero__content--button {
                            max-width: fit-content;
                        }
                    }
                `}</style>
        </section>
    );
};

export default HeroSection;

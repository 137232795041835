import {useContext} from 'react';
import Image from 'next/image';
import AppContext from '../AppContext';
import HiddenOnMobile from 'aac-components/components/HiddenOnMobile';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';

/**
 * This approach helps to prevent rendering multiple images as well as prevent a flicker on desktop when isMobile gets set to false
 */

const HeroImage = ({
    src = '',
    desktopSrc = '',
    alt = 'American Addiction Centers Image',
}) => {
    const {isMobile} = useContext(AppContext);

    return (
        <>
            {!isMobile && desktopSrc ? (
                <HiddenOnMobile>
                    <Image
                        src={desktopSrc}
                        priority={true}
                        alt={alt}
                        fill
                        sizes="100vw"
                        style={{objectFit: 'cover'}}
                    />
                </HiddenOnMobile>
            ) : (
                <HiddenOnDesktop>
                    <Image
                        src={src}
                        alt={alt}
                        priority={true}
                        fill
                        sizes="100vw"
                        style={{objectFit: 'cover'}}
                    />
                </HiddenOnDesktop>
            )}
        </>
    );
};
export default HeroImage;
